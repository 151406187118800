<template>
  <div>
    <!-- TODO: Turn this into CallScheduledDescriptorCard instead -->
    <DescriptorCard
      ref="descriptorCardRef"
      v-bind="Object.assign({}, $attrs)" v-on="$listeners"
      :fixed_params="fixed_params"
      :_index_params="index_params"
      :stateIdentifier="cache_key"
      :small="small"
      :allow_relationships_filter="allow_relationships_filter"
      :allow_properties_filter="allow_properties_filter"
      :allow_json_filter="allow_json_filter"
      :allow_predefined_filter="allow_predefined_filter"
      :allow_select_fields="allow_select_fields"
      :_predefined_filters="_predefined_filters"
      class_name="CallScheduled"
      title="Calls Scheduled"
      :_search_component="_search_component"
    >
      <template v-slot:modal_bottom="modal">
        <template v-if="!modal.loading">
          <!-- need to wait till fully loaded to get the account from CallScheduled  -->
          <!-- {{ modal.params }} -->

          <div class="p-2 p-sm-3">
            <hr />
            <b-button-toolbar class="justify-content-between">
              <div></div>
              <div>
                <b-button-group class="mx-1">
                  <b-btn
                    variant="success"
                    @click="
                      scheduledCallDone(modal.params);
                      modal.mutate('show', false);
                    "
                    >Call Done</b-btn
                  >
                </b-button-group>
              </div>
            </b-button-toolbar>
          </div>
        </template>
      </template>
    </DescriptorCard>

    <Modal v-if="modal_fixed_params" ref="modalRef" @close="onModalClose()" class_name="CallDone" :fixed_params="modal_fixed_params">
    </Modal>
  </div>
</template>

<script>
import CallScheduledSearch from '@/views/descriptor/classes/CallScheduled/CallScheduledSearch';

export default {
  components: {
    DescriptorCard: () => import('@/descriptor/coreui/DescriptorCard'),
    Modal: () => import('@/descriptor/coreui/Modal'),
  },
  props: {
    account: {
      // required: true,
    },
    user: {
      // required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    allow_relationships_filter: {
      type: Boolean,
      default: true,
    },
    allow_properties_filter: {
      type: Boolean,
      default: true,
    },
    allow_json_filter: {
      type: Boolean,
      default: false,
    },
    allow_predefined_filter: {
      type: Boolean,
      default: true,
    },
    allow_select_fields: {
      type: Boolean,
      default: true,
    },
    _predefined_filters: {
      type: Array,
    },
    _index_params: {
      type: Object,
    },
  },
  data: () => {
    return {
      modal_fixed_params: null,
    };
  },
  computed: {
    _search_component() {
      return CallScheduledSearch;
    },
    salesperson() {
      let user_salesperson = _.first(this.$auth.user[this.$d.getRelationAliasByNames('Salesperson', 'WithUser')]);
      // console.log('user_salesperson', user_salesperson);
      return user_salesperson;
    },
    fixed_params() {
      let fixed_params = {};

      if (this.account) {
        fixed_params = _.merge(fixed_params, this.$d.relationFixedParams('CallScheduled', 'ForAccount', [this.account]));
      }
      if (this.$auth.user) {
        // fixed_params = _.merge(fixed_params, this.$d.relationFixedParams('CallScheduled', 'ByUser', [this.$auth.user]));
        if (this.salesperson) {
          fixed_params = _.merge(fixed_params, this.$d.relationFixedParams('CallScheduled', 'BySalesperson', [this.salesperson]));
        }
      }

      return fixed_params;
    },
    index_params() {
      let index_params = {};

      if (this.account) {
        index_params = this.$d.mergeFilterParams(index_params, this.$d.relationIndexParams('CallScheduled', 'ForAccount', this.account['id']));
      }
      // if (this.$auth.user) {
      // index_params = this.$d.mergeFilterParams(index_params, this.$d.relationIndexParams('CallScheduled', 'ByUser', this.$auth.user['id']));
      // }
      if (this.salesperson) {
        index_params = this.$d.mergeFilterParams(index_params, this.$d.relationIndexParams('CallScheduled', 'BySalesperson', this.salesperson['id']));
      }

      if (this._index_params) {
        index_params = this.$d.mergeFilterParams(index_params, this._index_params);
      }

      return index_params;
    },
    cache_key() {
      let cache_key = '_billing_account';
      if (this.account) {
        cache_key += '_' + this.account['id'];
      }
      return cache_key;
    },
  },
  created() {
    this.log = console.log; //for debugging in templates
  },
  methods: {
    scheduledCallDone(call_scheduled) {
      // modal.params['_open_call_done'] = !modal.params['_open_call_done'];
      // // console.log(modal.params);
      // modal.mutate('params', Object.assign({}, modal.params)); //hacky way to make _open_call_done reactive
      // modal.mutate('show', false);

      // console.log(call_scheduled);

      let account = _.get(call_scheduled[this.$d.getRelationAliasByNames('CallScheduled', 'ForAccount')], [0]);
      let contact_person = _.get(call_scheduled[this.$d.getRelationAliasByNames('CallScheduled', 'ToContactPerson')], [0]);
      let contact_number = _.get(call_scheduled[this.$d.getRelationAliasByNames('CallScheduled', 'ToContactNumber')], [0]);

      //new call
      let new_call_scheduled = {};
      if (account) {
        new_call_scheduled - _.merge(new_call_scheduled, this.$d.relationFixedParams('CallScheduled', 'ForAccount', [account]));
      }

      if (contact_person) {
        new_call_scheduled - _.merge(new_call_scheduled, this.$d.relationFixedParams('CallScheduled', 'ToContactPerson', [contact_person]));
      }

      if (contact_number) {
        new_call_scheduled - _.merge(new_call_scheduled, this.$d.relationFixedParams('CallScheduled', 'ToContactNumber', [contact_number]));
      }

      if (this.$auth.user) {
        // new_call_scheduled - _.merge(new_call_scheduled, this.$d.relationFixedParams('CallScheduled', 'ByUser', [this.$auth.user]));
        if (this.salesperson) {
          new_call_scheduled - _.merge(new_call_scheduled, this.$d.relationFixedParams('CallScheduled', 'BySalesperson', [this.salesperson]));
        }
      }

      //event

      let event = {};
      if (account) {
        event - _.merge(event, this.$d.relationFixedParams('Event', 'ForAccount', [account]));
      }

      if (this.$auth.user) {
        event - _.merge(event, this.$d.relationFixedParams('Event', 'ByUser', [this.$auth.user]));
      }

      //call done
      let call_done = {};
      call_done = _.merge(call_done, this.$d.relationFixedParams('CallDone', 'ScheduleNewCall', [new_call_scheduled]));
      call_done = _.merge(call_done, this.$d.relationFixedParams('CallDone', 'ForScheduled', [call_scheduled]));
      call_done = _.merge(call_done, this.$d.relationFixedParams('CallDone', 'HasEvent', [event]));

      this.modal_fixed_params = _.cloneDeep(call_done); //makes sure not referenced

      setTimeout(() => {
        this.$refs['modalRef'].open();
      }, 0);
    },
    onModalClose() {
      this.$refs['descriptorCardRef'].get();
      this.modal_fixed_params = null; //this will destroy the modal element, to ensure reactivity upons initialization
    },
  },
};
</script>
